import css from './LandingCategories.module.css';
import tire from '../../../assets/dack.png';
import wheel from '../../../assets/hjul.png';
import rim from '../../../assets/falg.png';
import { Box, Card, Button } from '@mui/material';
import { createResourceLocatorString } from '../../../util/routes';

import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

export function LandingCategories() {
  const dispatch = useDispatch();
  const CategoryCard = props => {
    const history = useHistory();

    const handleClick = title => {
      const type = title === 'Hjul' ? 'wheel' : title === 'Fälg' ? 'rim' : 'tire';
      history.push(`annonser?pub_adType=${type}`);
      // dispatch({
      //   type: 'SET_LISTINGTYPE',
      //   payload: props.param,
      // });
    };

    return (
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          backgroundColor: 'var(--wheelplaceSteel)',
        }}
        className={css.categoryCard}
      >
        <img src={props.img} alt={props.title} />
        <Button
          sx={{
            backgroundColor: '#1E2F7B',
            boxShadow: 'none',
            textTransform: 'none',
          }}
          variant="contained"
          className={css.categoryButton}
          onClick={() => handleClick(props.title)}
        >
          {props.title}
        </Button>
      </Card>
    );
  };
  return (
    <Box
      component="section"
      width="100%"
      style={{ backgroundColor: 'var(--wheelplaceSteel)' }}
      padding="0 1rem 0 1rem"
    >
      <Box>
        <h2 style={{ marginTop: '0' }}>Upptäck våra kategorier</h2>
      </Box>
      <Box className={css.categoryContainer} component="section">
        <CategoryCard title="Hjul" img={wheel} param={0} />
        <CategoryCard title="Däck" img={tire} param={1} />
        <CategoryCard title="Fälg" img={rim} param={2} />
      </Box>
    </Box>
  );
}
