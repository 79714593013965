import css from './LandingHero.module.css';
import background from '../../../assets/manloadingwheelintocar.jpg';
import { Box, useMediaQuery } from '@mui/material';
import { SearchSection } from './SearchSection';
// import { USPBanner } from './USPBanner';
import { LandingCategories } from './LandingCategories';
import { CustomerReviews } from './CustomerReviews';
import { LayoutComposer, NamedLink } from '../../../components';
import TopbarContainer from '../../TopbarContainer/TopbarContainer';
import FooterContainer from '../../FooterContainer/FooterContainer';
import StaticPage from '../../PageBuilder/StaticPage';
import { FormattedMessage } from '../../../util/reactIntl';

const LandingHero = () => {
  const layoutAreas = `
    topbar
    main
    footer
  `;

  return (
    <StaticPage title="Wheelplace">
      <LayoutComposer areas={layoutAreas}>
        {() => (
          <>
            <TopbarContainer />

            <Box backgroundColor="var(--wheelplaceSteel)" component="main">
              <Box
                className={css.homeContainer}
                sx={{
                  backgroundColor: 'var(--wheelplaceSteel)',
                }}
              >
                <Box
                  component="section"
                  className={css.landingContainer}
                  sx={{
                    display: 'flex',
                    backgroundImage: `url(${background})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    width: '100%',
                  }}
                >
                  <Box component="section" width="100%">
                    <h1 style={{ color: '#FFE3BC', lineHeight: '2.5rem' }}>
                      <FormattedMessage id="LandingPage.heroTitle" />
                    </h1>
                  </Box>
                  <Box component="section" className={css.searchContainer}>
                    <SearchSection />
                  </Box>
                </Box>
                {/* Uncomment the <USPBanner /> component when we have added content for the USP banner. */}
                {/* <USPBanner /> */}

                <LandingCategories />

                {/* Uncomment the <CustomerReviews /> component when we have added content for the customer reviews. */}
                {/* <CustomerReviews /> */}

                <Box sx={{ padding: '16px 16px 28px 16px' }}>
                  <h2>Begagnade Däck och Fälgar – Köp och Sälj på Wheelplace</h2>
                  <ol>
                    <h3>Letar du efter högkvalitativa begagnade däck och fälgar?</h3>
                    <h3>
                      Upptäck Wheelplace, den ledande marknadsplatsen för köp och försäljning av
                      däck och fälgar online.
                    </h3>
                    <p>
                      Välkommen till Wheelplace, din pålitliga partner när du vill hitta de bästa
                      begagnade däcken och fälgarna till din bil. Hos oss på Wheelplace hittar du
                      ett brett sortiment av däck och fälgar som passar alla typer av bilar, är du
                      osäker eller har frågor hjälper vår erfarna kundsupport dig hela vägen.
                      Oavsett om du letar efter vinterdäck, sommardäck eller allrounddäck, har vi
                      något för alla säsonger och behov.
                    </p>
                    <p>
                      Upptäck vårt användarvänliga system där du enkelt kan lista dina egna däck och
                      fälgar eller bläddra igenom hundratals annonser för att hitta precis vad du
                      behöver. Varje annons är noggrant granskad av vårt erfarna team för att
                      säkerställa kvalitet och säkerhet.
                    </p>

                    <h3>Varför Wheelplace?</h3>
                    <li>
                      <p>
                        Brett Utbud: Från sparsamt använda sommardäck till nästan nya vinterdäck,
                        vårt sortiment täcker allt.
                      </p>
                    </li>
                    <li>
                      <p>
                        Enkelhet: Vår plattform är designad för att göra både köp och försäljning så
                        smidig som möjligt.
                      </p>
                    </li>
                    <li>
                      <p>
                        Trygghet och Tillit: Vi garanterar personlig kundservice och träffsäkra
                        resultat i vår marknadsplats.
                      </p>
                    </li>

                    <p>
                      Letar du efter ett specifikt märke eller modell? Använd vår avancerade
                      sökfunktion för att filtrera dina val och spara tid. På Wheelplace är vi
                      dedikerade till att göra din upplevelse så angenäm och effektiv som möjligt.
                      Joina hundratals nöjda köpare och säljare som väljer Wheelplace för handel med
                      begagnade däck och fälgar.
                    </p>
                    <p>
                      Dina nästa set däck och fälgar väntar på dig hos Wheelplace. Upptäck
                      enkelheten och tryggheten i att köpa och sälja begagnade däck och fälgar på
                      den självklara marknadsplatsen för hjul, däck och fälgar.
                    </p>
                    <p>
                      Ditt nästa set däck och fälgar väntar på dig hos Wheelplace. Bläddra igenom
                      vårt omfattande sortiment idag och upptäck hur enkelt och förmånligt det är
                      att handla hos oss. Wheelplace – där bra affärer rullar fram!
                    </p>
                  </ol>
                </Box>

                <Box sx={{ padding: '16px 16px 28px 16px' }}>
                  <h2>Så här fungerar Wheelplace</h2>
                  <ol>
                    <li>
                      <h3>Skapa en annons</h3>
                      <NamedLink
                        className={css.createListingLink}
                        name="NewListingPage"
                        style={{
                          backgroundColor: '#2A2273',
                          color: 'white',
                          alignSelf: 'center',
                          height: '60%',
                          display: 'flex',
                          borderRadius: '10px',
                        }}
                      >
                        <svg
                          width="34"
                          height="34"
                          viewBox="0 0 34 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ alignSelf: 'center', marginRight: '20px' }}
                        >
                          <path
                            d="M18.7 8.5H15.3V15.3H8.5V18.7H15.3V25.5H18.7V18.7H25.5V15.3H18.7V8.5ZM17 0C7.616 0 0 7.616 0 17C0 26.384 7.616 34 17 34C26.384 34 34 26.384 34 17C34 7.616 26.384 0 17 0ZM17 30.6C9.503 30.6 3.4 24.497 3.4 17C3.4 9.503 9.503 3.4 17 3.4C24.497 3.4 30.6 9.503 30.6 17C30.6 24.497 24.497 30.6 17 30.6Z"
                            fill="#F3F5F5"
                          />
                        </svg>
                        <span className={css.createListing} style={{ alignSelf: 'center' }}>
                          <FormattedMessage id="TopbarDesktop.createListing" />
                        </span>
                      </NamedLink>
                      <p>
                        Välj vilken produkt du vill sälja. Fyll i så mycket information om produkten
                        som möjligt för att göra det enkelt för köpare. Skriv en säljande rubrik och
                        sätt ditt pris. Om du har frågor tveka inte att höra av dig till vår
                        kundsupport för hjälp.
                      </p>
                    </li>
                    <li>
                      <h3>Få svar från köpare</h3>
                      <p>
                        Intresserade tar kontakt med dig via meddelande eller telefon om du angett
                        det i annonsen.
                      </p>
                    </li>
                    <li>
                      <h3>Möt upp eller bestäm fraktalternativ</h3>
                      <p>
                        Gör upp tillsammans med köparen hur du vill få betalt och hur varan skall
                        lämnas över eller möjligtvis fraktas.
                      </p>
                    </li>
                  </ol>
                </Box>
              </Box>
            </Box>
            <FooterContainer />
          </>
        )}
      </LayoutComposer>
    </StaticPage>
  );
};

export default LandingHero;
