export const inputStyles = {
  '& .MuiInputLabel-root': {
    fontSize: '18px',

    '&.MuiInputLabel-shrink': {
      fontSize: '18px',
    },
  },
  '& .MuiSvgIcon-root': {
    fontSize: '40px',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    '& legend': {
      display: 'block',
      width: 'auto',
    },
  },
  '@media (max-width: 768px)': {
    '& .MuiInputLabel-root': {
      fontSize: '16px', // Adjusted font size for smaller screens
    },
    '& .MuiSvgIcon-root': {
      fontSize: '30px', // Adjusted icon size for smaller screens
    },
  },
};
